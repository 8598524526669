$blue: #406bb2;
$light-blue: #658bcb;
$dark-blue: #213e7a;
$tab-bar-blue: #163169;
$pink: #f49596;
$orange: #f7a884;
$dark-yellow: #fdc16e;
$yellow: #ffcb66;
$blue-gradient: radial-gradient(circle, $blue 0%, $dark-blue 35%);
$pink-yellow-gradient: radial-gradient(circle, $pink 0%, $yellow 35%);
$grey: #ccc;
$light-grey: #f8f8f8;

$ui-font: 'Montserrat', sans-serif;
$font-weight-regular: 400;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$line-height: 1.4;

$mobile-width: 90vw;
$desktop-default-width: 768px;
$player-large-height: 432px;
$player-small-width: 480px;
$player-small-height: 270px;
$wv-max-content-width: 700px;

$z-index-app-logo: 20;
$z-index-submenu-and-mobile-menu: 15;
$z-index-small: 5;

$desktop-xl: 1900px;
$mobile-menu-breakpoint: 1100px;
$padding-desktop-menu-breakpoint: 1300px;

$flexbox-xlarge-breakpoint: 1200px;
$flexbox-large-breakpoint: 992px;
$flexbox-medium-breakpoint: 800px;
$flexbox-small-breakpoint: 768px;

$flexbox-content-small-width: 46rem;
$flexbox-content-large-width: 61rem;
$flexbox-content-xlarge-width: 71rem;


@mixin trim($numLines: null) {
  @if $numLines != null {
    display: -webkit-box;
    -webkit-line-clamp: $numLines;
    -webkit-box-orient: vertical;
    overflow: hidden;
  } @else {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
  }
}
